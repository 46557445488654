import React, { useContext } from 'react';
import cx from 'classnames';

import { isWindow, isDocument } from '../../../helpers/isClient';
import ScrollContext from '../../../context/ScrollContext';

const ScrollTopButton = () => {
  const { scrollY } = useContext(ScrollContext);

  const scrollToTop = () => {
    if (isWindow() && isDocument()) {
      const scrollFromTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollFromTop > 0) {
        const nextCord = Math.floor(scrollFromTop - scrollFromTop / 8);
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, nextCord);
      }
    }
  };

  const buttonClass = () => {
    return cx('sticked-button', {
      'sticked-button--show': scrollY > 100,
    });
  };

  return (
    <button className={buttonClass()} onClick={scrollToTop}>
      <span
        className="sticked-button__icon sticked-button--scroll icon-fn--up-open-mini"
        aria-hidden="true"
      />
    </button>
  );
};

export default ScrollTopButton;
