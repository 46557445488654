import EventBus from '../EventBus';
import FaqGTM from '../../components/listing-components/FAQ/tracking/FaqGTM';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';

export default class FaqTracking {
  static listen() {
    EventBus.on('component/FAQ/WasItHelpful/ClickYes', (data) => {
      const faqGTM = new FaqGTM(data);
      faqGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/FAQ/WasItHelpful/ClickYes', data);
      }
    });

    EventBus.on('component/FAQ/WasItHelpful/ClickNo', (data) => {
      const faqGTM = new FaqGTM(data);
      faqGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/FAQ/WasItHelpful/ClickNo', data);
      }
    });

    EventBus.on('component/FAQ/Question/Click', (data) => {
      const faqGTM = new FaqGTM(data);
      faqGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/FAQ/Question/Click', data);
      }
    });
  }
}
