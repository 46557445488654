export const checkIfSearchParamsAreTheSame = (
  previousQuearyString,
  newQueryString,
  arrSearchParamString,
) => {
  for (let i = 0; i < arrSearchParamString.length; i += 1) {
    const previousParamValue = getParameterByName(arrSearchParamString[i], previousQuearyString);
    const newParamValue = getParameterByName(arrSearchParamString[i], newQueryString);

    if (previousParamValue !== newParamValue) {
      return false;
    }
  }

  return true;
};

const getParameterByName = (paramName, url) => {
  const match = RegExp(`[?&]${paramName}=([^&]*)`).exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};
