import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import ProductTitleGTM from '../../components/teasers-components/ProductTitle/tracking/ProductTitleGTM';

export default class ProductTitleTracking {
  static listen() {
    EventBus.on('component/ProductTitle/productPageLoad', (data) => {
      const productTitleGTM = new ProductTitleGTM(data);
      productTitleGTM.onPageLoad();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/ProductTitle/productPageLoad', data);
      }
    });
  }
}
