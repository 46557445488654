import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import CartWidgetGTM from '../../components/navigation-components/CartWidget/tracking/CartWidgetGTM';

export default class CartWidgetTracking {
  static listen() {
    EventBus.on('component/CartWidget/click', (data) => {
      const cartWidgetGTM = new CartWidgetGTM(data);
      cartWidgetGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/CartWidget/click', data);
      }
    });
  }
}
