import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import PromotionSelectionGTM from '../../components/booking-components/PromotionSelection/tracking/PromotionSelectionGTM';

export default class PromotionSelectionTracking {
  static listen() {
    EventBus.on('component/PromotionSelection/addToCart/click', (data) => {
      const promotionSelectionGTM = new PromotionSelectionGTM(data);
      promotionSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionSelection/addToCart/click', data);
      }
    });

    EventBus.on('component/PromotionModal/QuantityUpdate', (data) => {
      const promotionSelectionGTM = new PromotionSelectionGTM(data);
      promotionSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionModal/QuantityUpdate', data);
      }
    });

    EventBus.on('component/PromotionSelection/onPageLoad', (data) => {
      const promotionSelectionGTM = new PromotionSelectionGTM(data);
      promotionSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionSelection/onPageLoad', data);
      }
    });
  }
}
