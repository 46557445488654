import React from 'react';
import qs from 'qs';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import {
  isExperienceEditorActive,
  dataApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import nprogress from 'nprogress';

import { ErrorPagesAPI } from 'api/errorPagesApi';

import { checkIfSearchParamsAreTheSame } from 'helpers/checkIfSearchParamsAreTheSame';
import { isWindow } from 'helpers/isClient';

import { dataFetcher } from './dataFetcher';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

class RouteHandler extends React.Component {
  NProgress = nprogress;

  constructor(props) {
    super(props);

    this.NProgress.configure({ showSpinner: false });

    this.state = {
      notFound: true,
      defaultLanguage: config.defaultLanguage,
    };

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (routeData !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (routeData && routeData.context && routeData.context.language) {
      this.state.defaultLanguage = routeData.context.language;
    }

    this.componentIsMounted = false;
    this.languageIsChanging = false;

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const routeData = this.extractRouteData();

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (
      typeof window !== 'undefined' &&
      !this.props.ssrRenderComplete &&
      this.props.setSsrRenderComplete
    ) {
      this.props.setSsrRenderComplete(true);
    }

    this.componentIsMounted = true;
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return {
      sitecore: {
        route,
        context,
      },
    };
  };

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    this.NProgress.start();
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language = this.props.route.match.params.lang || this.state.defaultLanguage;
    let queryString = this.props.route.location.search;

    // Internal links do not pass qs and anchors properly
    // we need to get them manually from sitecore route path
    if (!queryString) {
      const indexOfQueryString = sitecoreRoutePath.indexOf('?');
      const indexOfHash = sitecoreRoutePath.indexOf('#');

      if (indexOfQueryString > 0) {
        queryString = sitecoreRoutePath.substring(indexOfQueryString);
        sitecoreRoutePath = sitecoreRoutePath.substring(0, indexOfQueryString);
      }

      if (indexOfHash > 0) {
        queryString = sitecoreRoutePath.substring(indexOfHash);
        sitecoreRoutePath = sitecoreRoutePath.substring(0, indexOfHash);
      }
    }

    // get the route data for the new route
    getRouteData(sitecoreRoutePath, language, queryString).then((routeData) => {
      if (routeData?.sitecore?.route) {
        // set the sitecore context data and push the new route
        this.props.updateSitecoreContext({
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          ...routeData.sitecore.context,
        });
        this.setState({ notFound: false });
      } else {
        this.setState({ notFound: true }, () =>
          this.props.updateSitecoreContext(routeData?.sitecore?.context),
        );
      }

      this.NProgress.done();
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang || this.state.defaultLanguage;

    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    const shouldPreventRefetchOnAttractionSearchPage = () => {
      const isReservationPage = this.props.route.location.pathname
        .toLocaleLowerCase()
        .includes('/attractionsearch');

      if (!isReservationPage) {
        return true;
      }

      const shouldPreventRefetch = checkIfSearchParamsAreTheSame(
        previousProps.route.location.search,
        this.props.route.location.search,
        ['ThemeParkCode', 'VisitDate'],
      );

      return shouldPreventRefetch;
    };

    const shouldPreventRefetchOnHotelSearchPage = () => {
      const isHotelSearchPage = this.props.route.location.pathname
        .toLocaleLowerCase()
        .includes('/hotelsearch');

      if (!isHotelSearchPage) {
        return true;
      }

      const shouldPreventRefetch = checkIfSearchParamsAreTheSame(
        previousProps.route.location.search,
        this.props.route.location.search,
        ['HotelCode', 'checkin', 'checkout'],
      );

      return shouldPreventRefetch;
    };

    const shouldPreventRefetchOnPackageOptionPage = () => {
      const isPackageOptionPage = this.props.route.location.pathname
        .toLocaleLowerCase()
        .includes('/packageoptions');

      if (!isPackageOptionPage) {
        return true;
      }

      const shouldPreventRefetch = checkIfSearchParamsAreTheSame(
        previousProps.route.location.search,
        this.props.route.location.search,
        ['Code', 'VisitDate', 'checkin', 'checkout'],
      );

      return shouldPreventRefetch;
    };

    // don't change state (refetch route data) if the route has not changed
    if (
      existingRoute === newRoute &&
      shouldPreventRefetchOnAttractionSearchPage() &&
      shouldPreventRefetchOnHotelSearchPage() &&
      shouldPreventRefetchOnPackageOptionPage()
    ) {
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }

  handlePage404Redirection = async (routeData, url) => {
    if (url && isWindow()) {
      return window.location.assign(url);
    }

    return <DefaultErrorPage routeData={routeData} />;
  };

  formatSourceUrl = (value) => {
    if (value?.[0] === '/') {
      return value;
    }

    return `/${value}`;
  };

  checkVanityUrls = async (routeData) => {
    const url = this.props.route.location.pathname;
    const trimmedUrl = url
      .split(/\//)
      .splice(2)
      .join('/');
    const vanityUrl = routeData?.sitecore?.context?.vanityUrls?.find(
      (e) =>
        this.formatSourceUrl(e.SourceURL.toLowerCase()) === url.toLowerCase() ||
        this.formatSourceUrl(e.SourceURL) === this.formatSourceUrl(trimmedUrl.toLocaleLowerCase()),
    );

    try {
      const response = await getPage404Url();

      if (response && isWindow()) {
        if (vanityUrl) {
          return window.location.assign(vanityUrl.TargetURL);
        }

        this.handlePage404Redirection(routeData, response);
      }

      return <DefaultErrorPage routeData={routeData} />;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Route url fetch error', error);
      return <DefaultErrorPage routeData={routeData} />;
    }
  };

  render() {
    const { notFound } = this.state;
    const routeData = this.extractRouteData();
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const queryString = this.props.route.location.search;

    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    if (notFound && routeData) {
      if (!routeData?.sitecore?.route) {
        this.checkVanityUrls(routeData);
        return null;
      }

      return <DefaultErrorPage routeData={routeData} />;
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      return null;
    }

    // Render the app's root structural layout
    return (
      <Layout
        route={routeData.sitecore.route}
        sitecoreRoutePath={sitecoreRoutePath}
        queryString={queryString}
      />
    );
  }
}

export default withSitecoreContext({ updatable: true })(RouteHandler);

/**
 * Component for default blank error page
 * @param {Object} props using routeData object from props
 */
const DefaultErrorPage = ({ routeData }) => (
  <div>
    <Helmet>
      <title>{i18n.t('Page not found')}</title>
    </Helmet>
    <NotFound context={routeData?.sitecore?.context} />
  </div>
);

/**
 * Gets valid 404 page url for passed rawUrl param. If nothing received, return null.
 * @returns 404 page url.
 */
const getPage404Url = async () => {
  if (!isWindow()) return false;

  const response = await new ErrorPagesAPI().getPage404({
    rawUrl: `${window.location.pathname}${window.location.search}`,
  });

  if (response?.data?.PageNotFoundRedirectUrl) {
    return response?.data?.PageNotFoundRedirectUrl;
  }

  return null;
};

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language, queryString) {
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: { sc_lang: language, sc_apikey: config.sitecoreApiKey },
    fetcher: dataFetcher,
  };
  if (queryString) {
    Object.assign(
      fetchOptions.querystringParams,
      qs.parse(queryString, { ignoreQueryPrefix: true }),
    );
  }

  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error?.response?.data && error?.response?.status === 404) {
      return error.response.data;
    }

    // eslint-disable-next-line no-console
    console.error('Route data fetch error', error, error.response);

    return null;
  });
}
