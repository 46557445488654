import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import PaymentProcessingGTM from '../../components/booking-components/PaymentProcessing/tracking/PaymentProcessingGTM';

export default class PaymentProcessingTracking {
  static listen() {
    EventBus.on('component/PaymentProcessing/paymentSuccess', (data) => {
      const paymentProcessingGTM = new PaymentProcessingGTM(data);
      paymentProcessingGTM.onPaymentSuccess();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PaymentProcessing/paymentSuccess', data);
      }
    });

    EventBus.on('component/PaymentProcessing/confirmation', (data) => {
      const paymentProcessingGTM = new PaymentProcessingGTM(data);
      paymentProcessingGTM.onConfirmation();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PaymentProcessing/confirmation', data);
      }
    });
    
    EventBus.on('component/PaymentProcessingV2/confirmation', (data) => {
      const paymentProcessingGTM = new PaymentProcessingGTM(data);
      paymentProcessingGTM.onConfirmation();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PaymentProcessingV2/confirmation', data);
      }
    });
  }
}
