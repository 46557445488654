import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import FaqSearchGTM from '../../components/listing-components/FAQ/subcomponents/tracking/FaqSearchGTM';

export default class FaqSearchTracking {
  static listen() {
    EventBus.on('component/FAQ-Search/phraseOnSearch', (data) => {
      const faqGTM = new FaqSearchGTM(data);
      faqGTM.onSearch();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/FAQ-Search/phraseOnSearch', data);
      }
    });
  }
}
