import TagManager from 'react-gtm-module';

const interaction = (eventName, eventParams) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventParams,
    },
  });
};

export default { interaction };
