import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import OfferListingGTM from 'components/listing-components/OffersListing/tracking/OfferListingGTM';

export default class OfferListingTracking {
  static listen() {
    EventBus.on('component/OfferListing/onPageLoad', (data) => {
      const offerListingGTM = new OfferListingGTM(data);
      offerListingGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/OfferListing/onPageLoad', data);
      }
    });

    EventBus.on('component/OfferListing/addToCart/click', (data) => {
      const offerListingGTM = new OfferListingGTM(data);
      offerListingGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/OfferListing/addToCart/click', data);
      }
    });
  }
}
