import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import CounterGTM from 'components/simple-components/Counter/tracking/CounterGTM';

export default class CounterTracking {
  static listen() {
    EventBus.on('component/Cart/onDecreaseValue', (data) => {
      const counterGTM = new CounterGTM(data);
      counterGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Cart/onDecreaseValue', data);
      }
    });
  }
}
