import OfferGTM from 'components/booking-components/Offer/tracking/OfferGTM';
import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';

export default class OfferCartTracking {
  static listen() {
    EventBus.on('component/Offers/onViewRate', (data) => {
      const offerGTM = new OfferGTM(data);
      offerGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Offers/onViewRate', data);
      }
    });
  }
}
