import React from 'react';
import ReactDOM from 'react-dom';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';

const INTERNAL_LINK_PATH = 'a[href^="/"]';

/** Binds route handling to internal links within a rich text field */
class RouteLinkedRichText extends React.Component {
  constructor(props) {
    super(props);

    this.routeHandler = this.routeHandler.bind(this);
  }

  // handler function called on click of route links
  // pushes the click into the router history thus changing the route
  // props.history comes from the react-router withRouter() higher order component.
  routeHandler(event) {
    const closestElement = event.target.closest(INTERNAL_LINK_PATH);

    if (event.preventDefault) {
      event.preventDefault();
    }

    if (closestElement.hash) {
      this.props.history.push({
        pathname: closestElement.pathname,

        hash: closestElement.hash,
      });
    } else {
      this.props.history.push(closestElement.pathname);
    }
  }

  // rebinds event handlers to route links within this component
  // fired both on mount and update
  bindRouteLinks() {
    const hasText = this.props.field && this.props.field.value;
    const isEditing = this.props.editable !== false && this.props.field.editable;

    if (hasText && !isEditing) {
      const node = ReactDOM.findDOMNode(this);
      // selects all links that start with '/' - this logic may be inappropriate for some advanced uses
      const internalLinks = node.querySelectorAll(INTERNAL_LINK_PATH);

      internalLinks.forEach((link) => {
        // Since invites and booking portal are on old sitecore we can't use router for those links.
        // Change this when invites and booking will be on new sitecore.
        if (!link.href.includes('/reservations') && !link.href.includes('/rwsinvites')) {
          // the component can be updated multiple times during its lifespan,
          // and we don't want to bind the same event handler several times so unbind first
          link.removeEventListener('click', this.routeHandler, true);
          link.addEventListener('click', this.routeHandler, true);
        }
      });
    }
  }

  // called once when component is created
  componentDidMount() {
    this.bindRouteLinks();
  }

  // called if component data changes _after_ created
  componentDidUpdate() {
    this.bindRouteLinks();
  }

  render() {
    // strip the 'staticContext' prop from withRouter()
    // to avoid confusing React before we pass it down
    const { staticContext, ...props } = this.props;

    // all rich text fields to have responsive images by default
    props.className = cx(props.className, 'snippet-image-wrapper');

    return <RichText {...props} />;
  }
}

// augment the component with the react-router context using withRouter()
// this gives us props.history to push new routes
export default withRouter(RouteLinkedRichText);
