import { getSearchQuery } from '../filters-search/getSearchQuery';
import { isWindow } from '../isClient';

const toggle = () => {
  const query = getSearchQuery('gtmLoggerEnabled');

  if (query && query === 'true') {
    sessionStorage.setItem('gtmLoggerEnabled', true);
  }

  if (query && query === 'false') {
    sessionStorage.removeItem('gtmLoggerEnabled');
  }
};

const log = (path, data) => {
  if (!isWindow()) return;
  console.log(
    `%c GTM event called: ${path} %o`,
    'background: #c79304; color: #fff',
    data,
    window.dataLayer ?? {},
  );
};

const isEnabled = () => {
  return sessionStorage.getItem('gtmLoggerEnabled');
};

export default { isEnabled, toggle, log };
