import React from 'react';
import PropTypes from 'prop-types';

const HtmlImage = (props) => {
  const attributes = {
    width: props.width ?? null,
    height: props.height ?? null,
    style: props.style ?? null,
  };

  const customSrcWithMhAndMw = (src) => {
    if (!src) return false;
    const sizeParameters = () => {
      if (props.mw && props.mh) {
        return `?mw=${props.mw}&mh=${props.mh}`;
      } else if (props.mw && !props.mh) {
        return `?mw=${props.mw}`;
      } else if (!props.mw && props.mh) {
        return `?mh=${props.mh}`;
      }
      return '';
    };
    const url = `${src.split('?')[0]}${sizeParameters()}`;

    return url;
  };

  const imageProps = { ...props };

  return (
    <img
      loading="lazy"
      {...imageProps}
      {...attributes}
      src={customSrcWithMhAndMw(props.src)}
      alt={props.alt}
      mh={null}
      mw={null}
    />
  );
};

HtmlImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  mh: PropTypes.number,
  mw: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default HtmlImage;
