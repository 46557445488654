import React, { useState, createContext, useContext, useEffect } from 'react';
import { SitecoreContextReactContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';

import { isWindow } from '../helpers/isClient';

import { productTypes } from '../consts/productTypes';
import { pathNames } from '../consts/pathNames';
import bookingDefaults from '../modules/BookingDefaults';
import BookingLocalStorageHelper from '../helpers/booking/bookingLocalStorageHelper';

const BookingContext = createContext();

const getReservationPageProductType = () => {
  if (!isWindow()) return '';

  const pathname = window.location.pathname.toLowerCase() ?? '';

  if (pathname.includes(productTypes.ATTRACTION.toLowerCase())) {
    return productTypes.ATTRACTION;
  }

  if (pathname.includes(productTypes.EVENT.toLowerCase())) {
    return productTypes.EVENT;
  }

  if (pathname.includes(productTypes.HOTEL.toLowerCase())) {
    return productTypes.HOTEL;
  }

  if (pathname.includes(productTypes.PACKAGE.toLowerCase())) {
    return productTypes.PACKAGE;
  }

  return '';
};

const BookingProvider = (props) => {
  const sitecoreContextFactory = useContext(SitecoreContextReactContext);
  const location = useLocation();

  const sitecoreContext = () => {
    return typeof sitecoreContextFactory?.getSitecoreContext === 'function'
      ? sitecoreContextFactory?.getSitecoreContext()
      : null;
  };

  const checkIfOnPathName = (pathName) => {
    if (isWindow()) {
      return window.location.pathname.includes(pathName);
    }

    return false;
  };

  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [isBookingWidgetOpen, setIsBookingWidgetOpen] = useState(false);
  const [heroBannerBookingWidgetNode, setHeroBannerBookingWidgetNode] = useState(null);
  const [months, setMonths] = useState([]);
  const [productId, setProductId] = useState(sitecoreContext()?.selectedProduct?.productId);
  const [productType, setProductType] = useState(sitecoreContext()?.selectedProduct?.productType);
  const [productReservationUrl, setProductReservationUrl] = useState(
    sitecoreContext()?.selectedProduct?.reservationUrl,
  );
  const [productReservationTitle, setProductReservationTitle] = useState(
    sitecoreContext()?.selectedProduct?.reservationTitle,
  );
  const [withLikeShare, setWithLikeShare] = useState(false);
  const [isBookNowHidden, setIsBookNowHidden] = useState(false);
  const [widget, setWidget] = useState(sitecoreContext()?.widget);
  const [reservationPageProductType, setReservationPageProductType] = useState('');
  const [continueShoppingReservationOfferType, setContinueShoppingReservationOfferType] = useState(
    '',
  );

  const [activePathNames, setActivePathNames] = useState({
    [pathNames.HOTEL_EDIT]: false,
    [pathNames.HOTEL_SEARCH]: false,
    [pathNames.RESERVATIONS]: false,
    [pathNames.RWS_INVITES]: false,
    [pathNames.ADDED_TO_CART]: false,
  });
  const [cartPage, setCartPage] = useState({});
  const [checkoutPage, setCheckoutPage] = useState({});
  const [addedToCartPage, setAddedToCartPage] = useState({});
  const [invitesLogo, setInvitesLogo] = useState('');

  const [booking, setBooking] = useState(bookingDefaults);
  const [bookingPromotionOffer, setBookingPromotionOffer] = useState({});
  const [invalidCartItems, setInvalidCartItems] = useState(new Set());
  const [latestAddedCartItem, setLatestAddedCartItem] = useState({});
  const [roomTypeCodesState, setRoomTypeCodesState] = useState('');
  const [isOfferNotAvailable, setIsOfferNotAvailable] = useState('Offers not available');
  const [dataGTM, setDataGTM] = useState('');
  const [GTMPackageOption, setPackageOption] = useState('');

  useEffect(() => {
    setReservationPageProductType(getReservationPageProductType());

    setActivePathNames({
      [pathNames.HOTEL_EDIT]: checkIfOnPathName(pathNames.HOTEL_EDIT),
      [pathNames.HOTEL_SEARCH]: checkIfOnPathName(pathNames.HOTEL_SEARCH),
      [pathNames.RESERVATIONS]: checkIfOnPathName(pathNames.RESERVATIONS),
      [pathNames.RWS_INVITES]: checkIfOnPathName(pathNames.RWS_INVITES),
      [pathNames.ADDED_TO_CART]: checkIfOnPathName(pathNames.ADDED_TO_CART),
    });
  }, [location.pathname]);

  useEffect(() => {
    const bookingLocalStorageHelper = new BookingLocalStorageHelper();
    bookingLocalStorageHelper.setBooking(booking);
  }, [booking]);

  return (
    <BookingContext.Provider
      value={{
        daysOfWeek,
        activePathNames,
        heroBannerBookingWidgetNode,
        isBookNowHidden,
        isBookingWidgetOpen,
        months,
        productId,
        productType,
        productReservationUrl,
        productReservationTitle,
        reservationPageProductType,
        continueShoppingReservationOfferType,
        withLikeShare,
        widget,
        cartPage,
        checkoutPage,
        addedToCartPage,
        booking,
        bookingPromotionOffer,
        invalidCartItems,
        invitesLogo,
        latestAddedCartItem,
        roomTypeCodesState,
        isOfferNotAvailable,
        dataGTM,
        GTMPackageOption,
        setIsOfferNotAvailable,
        setRoomTypeCodesState,
        setDaysOfWeek,
        setIsBookingWidgetOpen,
        setIsBookNowHidden,
        setHeroBannerBookingWidgetNode,
        setMonths,
        setProductId,
        setProductType,
        setProductReservationUrl,
        setProductReservationTitle,
        setReservationPageProductType,
        setContinueShoppingReservationOfferType,
        setWithLikeShare,
        setWidget,
        setCartPage,
        setCheckoutPage,
        setAddedToCartPage,
        setBooking,
        setBookingPromotionOffer,
        setInvalidCartItems,
        setInvitesLogo,
        setLatestAddedCartItem,
        setDataGTM,
        setPackageOption,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};

export default BookingContext;

export { BookingProvider };
