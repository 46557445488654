import React, { useState, useEffect, useCallback, useRef } from 'react';

import VIEW_MODES from '../consts/viewModes';
import useResizeDebounce from '../helpers/useResizeDebounce';

const AppContext = React.createContext();
const DEBOUNCE_TIME = 30;

const AppProvider = (props) => {
  const [viewMode, setViewMode] = useState(VIEW_MODES.DESKTOP);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(true);
  const [headerSize, setHeaderSize] = useState(0);

  const nodeRef = useRef();
  const [debouncedViewMode] = useResizeDebounce(nodeRef, DEBOUNCE_TIME);

  const handleDebouncedViewMode = useCallback(() => {
    setViewMode(debouncedViewMode);
  }, [debouncedViewMode]);

  useEffect(() => {
    handleDebouncedViewMode();
  }, [handleDebouncedViewMode, viewMode]);

  return (
    <AppContext.Provider
      value={{
        viewMode,
        mobileMenuIsOpen,
        headerSize,
        notificationsVisible,
        setMobileMenuIsOpen,
        setHeaderSize,
        setNotificationsVisible,
      }}
    >
      <span id="AppContextRef" className="hidden" ref={nodeRef} />
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;

export { AppProvider };
