import { isWindow } from '../isClient';

export const getSearchQuery = (key = '') => {
  if (isWindow()) {
    const params = new URLSearchParams(window.location.search);
    // eslint-disable-next-line
    for (const [name, value] of params) {
      if (name.toLowerCase() === key.toLowerCase()) {
        return value;
      }
    }
  }

  return '';
};

export const getAllSearchQuery = (key = '') => {
  if (isWindow()) {
    const params = new URLSearchParams(window.location.search);
    return params.getAll(key);
  }
  return [];
};
