import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import RoomGTM from '../../components/listing-components/RoomsListing/tracking/RoomGTM';

export default class RoomTracking {
  static listen() {
    EventBus.on('component/Room/AddToCart/click', (data) => {
      const roomGTM = new RoomGTM(data);
      roomGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Room/AddToCart/click', data);
      }
    });
  }
}
