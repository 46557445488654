import { isWindow } from './isClient';

export const appendLinkQueryStringParameter = (sitecoreLinkField, key, value) => {
  if (!sitecoreLinkField.value || !key || !value) {
    return sitecoreLinkField;
  }

  const linkField = sitecoreLinkField;

  if (linkField.value.linktype === 'internal') {
    if (!linkField.value.querystring) {
      linkField.value.querystring = `?${key}=${value}`;
    } else if (linkField.value.querystring.indexOf(key) === -1) {
      linkField.value.querystring += `&${key}=${value}`;
    }
  } else if (linkField.value.linktype === 'external') {
    linkField.value.href = appendUrlQueryStringParameter(linkField.value.href, key, value);
  }

  return linkField;
};

export const appendUrlQueryStringParameter = (urlString, queryStringKey, queryStringValue) => {
  if (!urlString || !queryStringKey || !queryStringValue) {
    return urlString;
  }

  let result = urlString;

  if (
    result.indexOf(`?${queryStringKey}=`) === -1 &&
    result.indexOf(`&${queryStringKey}=`) === -1
  ) {
    result = result.concat(
      `${result.indexOf('?') === -1 ? '?' : '&'}${queryStringKey}=${queryStringValue}`,
    );
  }

  return result;
};

export const setSearchParam = (searchParams, queryStringKey, queryStringValue) => {
  if (!searchParams || !queryStringKey || !queryStringValue) {
    return searchParams;
  }

  const regex = new RegExp(`([?&])${queryStringKey}=.*?(&|$)`, 'i');
  const separator = searchParams.indexOf('?') !== -1 ? '&' : '?';

  if (searchParams.match(regex)) {
    return searchParams.replace(regex, `$1${queryStringKey}=${queryStringValue}$2)`);
  }

  return `${searchParams}${separator}${queryStringKey}=${queryStringValue}`;
};

export const getHrefWithQueryString = (sitecoreLink) => {
  if (!sitecoreLink) {
    return null;
  }

  const { href, querystring, anchor } = sitecoreLink;
  let output = href;

  const isQsInProperFormat = querystring?.startsWith('?');

  if (querystring && isQsInProperFormat) {
    output += querystring;
  }

  if (querystring && !isQsInProperFormat) {
    output += `?${querystring}`;
  }

  const validAnchor = anchor || '';
  output += validAnchor;

  return output;
};

export const prepareImageSrcForCustomParameters = (imageSrcUrl) => {
  if (!imageSrcUrl) {
    return imageSrcUrl;
  }

  const index = imageSrcUrl.indexOf('?');
  const result = index > -1 ? imageSrcUrl.substring(0, index) : imageSrcUrl;

  return result;
};

export const getPageUrl = () => {
  if (isWindow()) {
    return `${window.location.pathname}${window.location.search}`;
  }

  return '';
};
