import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import PromotionModalGTM from '../../components/booking-components/Modals/tracking/PromotionModalGTM';

export default class PromotionSelectionTracking {
  static listen() {
    EventBus.on('component/PromotionModal/addToCart/click', (data) => {
      const promotionModalGTM = new PromotionModalGTM(data);
      promotionModalGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionModal/addToCart/click', data);
      }
    });
  }
}
