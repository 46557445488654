import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import AttractionTicketSelectionGTM from '../../components/booking-components/AttractionTicketSelection/tracking/AttractionTicketSelectionGTM';

export default class AttractionTicketSelectionTracking {
  static listen() {
    EventBus.on('component/AttractionTicketSelection/AddToCart/click', (data) => {
      const attractionTicketSelectionGTM = new AttractionTicketSelectionGTM(data);
      attractionTicketSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/AttractionTicketSelection/AddToCart/click', data);
      }
    });

    EventBus.on('component/AttractionTicketSelection/onPageLoad', (data) => {
      const attractionTicketSelectionGTM = new AttractionTicketSelectionGTM(data);
      attractionTicketSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/AttractionTicketSelection/onPageLoad', data);
      }
    });
  }
}
