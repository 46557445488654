import isEmpty from 'lodash/isEmpty';
import TagManagerWrapper from '../../../../helpers/tracking/TagManagerWrapper';

export default class OfferGTM {
  constructor(data) {
    this.eventName = data?.name ?? '';
    this.eventParams = data?.params ?? {};
  }

  get isEnabled() {
    return !isEmpty(this.eventName) && !isEmpty(this.eventParams);
  }

  click() {
    if (this.isEnabled) {
      TagManagerWrapper.interaction(this.eventName, this.eventParams);
    }
  }
}
