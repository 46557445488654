import { HERO_BANNER_NAME, MEDIA_BANNER_NAME } from '../consts/componentsName';

import { getMobileOrDesktopField } from '../components/banners-components/HeroBanner/HeroSlide.jsx';

const getFirstSlide = (slides = []) => slides[0]?.fields?.background?.value?.src ?? '';

export const getBannerImgPreloadHref = (components) => {
  const heroBannerIndex = components.findIndex((el) => el.componentName === HERO_BANNER_NAME);
  const mediaBannerIndex = components.findIndex((el) => el.componentName === MEDIA_BANNER_NAME);

  if (heroBannerIndex >= 0) {
    return getHeroBannerImgPreloadHref(components, heroBannerIndex);
  }

  if (mediaBannerIndex >= 0) {
    return getMediaBannerImgPreloadHref(components, mediaBannerIndex);
  }

  return '';
};

export const getMediaBannerImgPreloadHref = (components, index) => {
  const slides = components[index]?.fields?.children ?? [];

  const images = slides.filter((slide) => !slide?.fields?.backgroundVideo?.value ?? false);
  const videos = slides.filter((slide) => slide?.fields?.backgroundVideo?.value ?? false);
  const imagesFirstSlide = getFirstSlide(images);

  if (!videos.length) {
    return imagesFirstSlide;
  }

  if (!images.length) {
    const videosFirstSlide = getFirstSlide(videos);

    return videosFirstSlide;
  }

  return imagesFirstSlide;
};

export const getHeroBannerImgPreloadHref = (components, index) => {
  const slides = components[index]?.fields?.children ?? [];
  const firstSlide = slides[0];
  const [backgroundVideo] = getMobileOrDesktopField(
    firstSlide,
    'mobile',
    'desktopBackgroundVideo',
    'mobileBackgroundVideo',
  );
  const isVideoSlide = backgroundVideo?.value?.src ?? '';

  if (isVideoSlide) {
    const [backgroundImage] = getMobileOrDesktopField(
      firstSlide,
      'mobile',
      'desktopBackground',
      'mobileBackground',
    );

    return backgroundImage?.value?.src ?? '';
  }

  const [backgroundImage] = getMobileOrDesktopField(
    firstSlide,
    'mobile',
    'desktopBackground',
    'mobileBackground',
  );

  return backgroundImage?.value?.src ?? '';
};
