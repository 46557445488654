import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import RemoveFromCartPopupGTM from '../../components/booking-components/RemoveFromCartPopup/tracking/RemoveFromCartGTM';

export default class RemoveFromCartPopupTracking {
  static listen() {
    EventBus.on('component/RemoveFromCartPopup/RemoveFromCart/click', (data) => {
      const removeFromCartPopupGTM = new RemoveFromCartPopupGTM(data);
      removeFromCartPopupGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/RemoveFromCartPopup/RemoveFromCart/click', data);
      }
    });

    EventBus.on('component/RemoveFromCartPopup/RemoveFromCartV2/click', (data) => {
      const removeFromCartPopupGTM = new RemoveFromCartPopupGTM(data);
      removeFromCartPopupGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/RemoveFromCartPopup/RemoveFromCartV2/click', data);
      }
    });
  }
}
