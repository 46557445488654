import React, { createContext, useEffect, useState } from 'react';
import BreadcrumbTrail from '../helpers/BreadcrumbTrail';

const BreadcrumbContext = createContext();

const BreadcrumbProvider = (props) => {
  const [breadcrumbData, setBreadcrumbConfig] = useState([]);

  useEffect(() => {
    BreadcrumbTrail.createScriptPlaceholder();
  }, []);

  useEffect(() => {
    if (breadcrumbData.length) {
      BreadcrumbTrail.setBreadcrumbTrail(breadcrumbData);
    }
  }, [breadcrumbData]);

  const setBreadcrumbData = (data) => {
    setBreadcrumbConfig(data);
  };

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbData,
        setBreadcrumbData,
      }}
    >
      {props.children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbContext;

export { BreadcrumbProvider };
