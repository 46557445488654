import BookingWidgetNaviTracking from './tracking/BookingWidgetNavi.tracking';
import FaqTracking from './tracking/FAQ.tracking';
import GridListingCardTracking from './tracking/GridListingCard.tracking';
import ProductTitleTracking from './tracking/ProductTitle.tracking';
import CartWidgetTracking from './tracking/CartWidget.tracking';
import SearchTracking from './tracking/Search.tracking';
import FaqSearchTracking from './tracking/FAQ-Search.tracking';
import OffersGridListingTracking from './tracking/OffersGridListing.tracking';
import AttractionTicketSelectionTracking from './tracking/AttractionTicketSelection.tracking';
import Room from './tracking/Room.tracking';
import SelectionProgress from './tracking/SelectionProgress.tracking';
import RemoveFromCartPopupTracking from './tracking/RemoveFromCartPopup.tracking';
import CartSubtotalTracking from './tracking/CartSubtotal.tracking';
import PaymentProcessingTracking from './tracking/PaymentProcessing.tracking';
import ButtonTracking from './tracking/Button.tracking';
import AddedToCartTracking from './tracking/AddedToCart.tracking';
import CartTracking from './tracking/Cart.tracking';
import PromotionSelectionTracking from './tracking/PromotionSelection.tracking';
import PromotionModalTracking from './tracking/PromotionModal.tracking';
import OfferCartTracking from './tracking/Offer.tracking';
import OfferConfigurationTracking from './tracking/OfferConfiguration.tracking';
import OfferListingTracking from './tracking/OfferListing.tracking';
import RoomSelectionTracking from './tracking/RoomSelection.tracking';
import CounterTracking from './tracking/Counter.tracking';

export default class EventsCollector {
  static registerFor(app) {
    switch (app) {
      case 'default': {
        BookingWidgetNaviTracking.listen();
        FaqTracking.listen();
        FaqSearchTracking.listen();
        GridListingCardTracking.listen();
        ProductTitleTracking.listen();
        CartWidgetTracking.listen();
        SearchTracking.listen();
        OffersGridListingTracking.listen();
        AttractionTicketSelectionTracking.listen();
        Room.listen();
        SelectionProgress.listen();
        RemoveFromCartPopupTracking.listen();
        CartSubtotalTracking.listen();
        PaymentProcessingTracking.listen();
        ButtonTracking.listen();
        AddedToCartTracking.listen();
        CartTracking.listen();
        PromotionSelectionTracking.listen();
        PromotionModalTracking.listen();
        OfferCartTracking.listen();
        OfferConfigurationTracking.listen();
        OfferListingTracking.listen();
        RoomSelectionTracking.listen();
        CounterTracking.listen();
        break;
      }

      default:
        break;
    }
  }
}
