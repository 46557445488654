import EventBus from '../EventBus';
import SearchGTM from '../../components/listing-components/SearchResults/tracking/SearchGTM';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';

export default class SearchTracking {
  static listen() {
    EventBus.on('component/Search/recentSearch/Click', (data) => {
      const searchGTM = new SearchGTM(data);
      searchGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Search/recentSearch/Click', data);
      }
    });

    EventBus.on('component/Search/popularSearch/Click', (data) => {
      const searchGTM = new SearchGTM(data);
      searchGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Search/popularSearch/Click', data);
      }
    });

    EventBus.on('component/Search/textSearch/Click', (data) => {
      const searchGTM = new SearchGTM(data);
      searchGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Search/textSearch/Click', data);
      }
    });

    EventBus.on('component/Search/similarSearch/Click', (data) => {
      const searchGTM = new SearchGTM(data);
      searchGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Search/similarSearch/Click', data);
      }
    });

    EventBus.on('component/Search/filtersApplied/Click', (data) => {
      const searchGTM = new SearchGTM(data);
      searchGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/Search/filtersApplied/Click', data);
      }
    });
  }
}
