import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import OfferConfigurationGTM from 'components/booking-components/OfferConfiguration/tracking/OfferConfigurationGTM';

export default class OfferConfigurationTracking {
  static listen() {
    EventBus.on('component/OfferConfiguration/onSelectOffer', (data) => {
      const offerConfigurationGTM = new OfferConfigurationGTM(data);
      offerConfigurationGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/OfferConfiguration/onSelectOffer', data);
      }
    });

  }
}
