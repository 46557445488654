import React, { useEffect, useState, useMemo } from 'react';
import {
  Placeholder,
  VisitorIdentification,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import TagManager from 'react-gtm-module';

import GoogleTagManagerLogger from 'helpers/tracking/GoogleTagManagerLogger';
import { isDocument, isWindow } from 'helpers/isClient';
import { getBannerImgPreloadHref } from 'helpers/banersPreloadImg';

import NavigationStaticPlaceholder from 'components/navigation-components/NavigationStaticPlaceholder';
import ScrollTopButton from 'components/navigation-components/ScrollTopButton';

import EventsCollector from 'modules/EventsCollector';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import './assets/css/revamp.scss';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, sitecoreContext, sitecoreRoutePath, queryString }) => {
  const hasGreyBackground = route.fields?.greyBackground?.value ?? false;
  const language = sitecoreContext?.language ?? '';
  const pageTitle = route.fields?.pageTitle?.value ?? 'Page';
  const pageDescription = route.fields?.description?.value ?? '';
  const pageKeywords = route.fields?.metaKeywords?.value ?? '';
  const ogImage = route.fields?.ogImage?.value?.src ?? sitecoreContext?.fallbackOgImage ?? '';
  const ogSitename = route.fields?.ogSiteName?.value ?? '';
  const ogTitle = route.fields?.ogTitle?.value ?? '';
  const ogType = route.fields?.ogType?.value ?? '';
  const fontDefault = sitecoreContext?.route?.fields?.fontTheme?.value ?? '';

  const [gtmId, setGtmId] = useState('');

  if (isDocument()) {
    if (hasGreyBackground) {
      document.body.classList.add('body-grey');
    } else {
      document.body.classList.remove('body-grey');
    }
  }

  const getBannerImg = useMemo(() => {
    const placeholderComponents = route?.placeholders?.top ?? [];

    if (!placeholderComponents.length) {
      return '';
    }

    return getBannerImgPreloadHref(placeholderComponents);
  }, [route]);

  const getPageAddress = () => {
    if (!ogImage) {
      return '/';
    }

    return ogImage.slice(0, ogImage.indexOf('/-/') + 1);
  };

  const getRoutePath = () => {
    if (sitecoreRoutePath && sitecoreRoutePath !== '/') {
      return sitecoreRoutePath;
    }

    return '';
  };

  const getCanonicalLink = () => {
    return <link rel="canonical" href={computeLinkHref()} />;
  };

  const getAlternateLinks = () => {
    const supportedLanguages = sitecoreContext?.supportedLanguages ?? [];

    if (supportedLanguages.length) {
      return supportedLanguages.map(({ code }) => (
        <link key={code} rel="alternate" hreflang={code} href={computeLinkHref(code)} />
      ));
    }

    return null;
  };

  const computeLinkHref = (lang = language) => {
    return getPageAddress() + lang + getRoutePath() + queryString;
  };

  useEffect(() => {
    const gtmIdFromProps = sitecoreContext?.navigation?.sitecore?.route?.fields?.gtmId?.value ?? '';

    if (gtmIdFromProps && isDocument() && isWindow()) {
      if (!window.dataLayer) {
        setGtmId(gtmIdFromProps);
  
        const tagManagerArgs = {
          gtmId: gtmIdFromProps,
        };
  
        TagManager.initialize(tagManagerArgs);
      }
    }

    /** Scrolls to top on page change */
    if (isWindow()) {
      window.scrollTo(0, 0);
    }
  }, [sitecoreContext]);

  useEffect(() => {
    if (gtmId && isDocument() && isWindow()) {
      GoogleTagManagerLogger.toggle();
      EventsCollector.registerFor('default');
    }
  }, [gtmId]);

  useEffect(() => {
    if (isWindow() && isDocument()) {
      setTimeout(() => {
        const isUrlHaveHash = window.location.hash.substring(1);
        const element = document.getElementById(isUrlHaveHash);
        const singlePageNavigation = document.getElementsByClassName('single-page-navigation');
        const headerOffset = singlePageNavigation ? 240 : 180;

        if (isUrlHaveHash && element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 2500);
    }
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: language,
          class: fontDefault === 'luxury' || fontDefault === 'Luxury' ? 'f-luxury' : '',
        }}
      >
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:type" content={ogType} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content={ogSitename} />

        {getCanonicalLink()}
        {getAlternateLinks()}
        <link
          rel="alternate"
          hreflang="x-default"
          href={`${getPageAddress()}en${getRoutePath()}${queryString}`}
        />

        {getBannerImg && <link rel="preload" href={getBannerImg} as="image" />}
      </Helmet>

      {gtmId && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            loading="lazy"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            title={gtmId}
          />
        </noscript>
      )}

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />

      <NavigationStaticPlaceholder name="shared-header" />
      <Placeholder name="top" rendering={route} />

      <div className="fluid-container" id="container">
        <div className="rws-portal-container col-lg-4" id="rws-portal-container" />

        <Placeholder name="container" rendering={route} />
      </div>

      <Placeholder name="bottom" rendering={route} />
      <NavigationStaticPlaceholder name="shared-footer" />
      <div id="modal-root"></div>

      <ScrollTopButton />
    </>
  );
};

export default withSitecoreContext()(Layout);
