import EventBus from '../EventBus';
import BookingWidgetNaviGTM from '../../components/bookingWidget-components/BookingWidgetNavi/tracking/BookingWidgetNaviGTM';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';

export default class BookingWidgetNaviTracking {
  static listen() {
    EventBus.on('component/BookingWidgetNavi/BookNowButton/click', (data) => {
      const bookingWidgetNaviGTM = new BookingWidgetNaviGTM(data);
      bookingWidgetNaviGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/BookingWidgetNavi/BookNowButton/click', data);
      }
    });

    EventBus.on('component/BookingWidgetNavi/ContinueButton/click', (data) => {
      const bookingWidgetNaviGTM = new BookingWidgetNaviGTM(data);
      bookingWidgetNaviGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/BookingWidgetNavi/ContinueButton/click', data);
      }
    });

    EventBus.on('component/BookingWidgetNavi/CategoryTab/click', (data) => {
      const bookingWidgetNaviGTM = new BookingWidgetNaviGTM(data);
      bookingWidgetNaviGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/BookingWidgetNavi/CategoryTab/click', data);
      }
    });
  }
}
