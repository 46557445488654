import React from 'react';

import { AppProvider } from './context/AppContext';
import { BookingProvider } from './context/BookingContext';
import { BreadcrumbProvider } from './context/BreadcrumbContext';
import { FormProvider } from './context/FormContext';
import { PopupProvider } from './context/PopupContext';
import { ScrollProvider } from './context/ScrollContext';

const ProviderWrappers = ({ children }) => (
  <AppProvider>
    <BreadcrumbProvider>
      <ScrollProvider>
        <BookingProvider>
          <PopupProvider>
            <FormProvider>{children}</FormProvider>
          </PopupProvider>
        </BookingProvider>
      </ScrollProvider>
    </BreadcrumbProvider>
  </AppProvider>
);

export default ProviderWrappers;
