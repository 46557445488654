import { useState, useEffect } from 'react';
import { isWindow } from './isClient';
import checkViewMode from './viewMode';

export default (node, time) => {
  const [state, setState] = useState(checkViewMode());

  const onResize = () => {
    const handler = setTimeout(() => {
      if (node.current) {
        setState(checkViewMode());
      }
    }, time);

    return () => clearTimeout(handler);
  };

  useEffect(() => {
    setState(checkViewMode());
  }, []);

  useEffect(() => {
    if (isWindow()) {
      window.addEventListener('resize', onResize);
    }

    return () => {
      if (isWindow()) {
        window.removeEventListener('resize', onResize);
      }
    };
  }, [checkViewMode, state]); // eslint-disable-line

  return [state];
};
