import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import RoomSelectionGTM from 'components/booking-components/RoomSelection/tracking/RoomSelectionGTM';

export default class RoomSelectionTracking {
  static listen() {
    EventBus.on('component/RoomSubSelection/addToCart', (data) => {
      const roomSelectionGTM = new RoomSelectionGTM(data);
      roomSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/RoomSubSelection/addToCart', data);
      }
    });

    EventBus.on('component/PromotionRoomSelection/onPage', (data) => {
      const roomSelectionGTM = new RoomSelectionGTM(data);
      roomSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionRoomSelection/onPage', data);
      }
    });

    EventBus.on('component/PromotionRoomSelection/onPageLoad', (data) => {
      const roomSelectionGTM = new RoomSelectionGTM(data);
      roomSelectionGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/PromotionRoomSelection/onPageLoad', data);
      }
    });
  }
}
