import React, { useState, createContext } from 'react';

const FormContext = createContext();

const FormProvider = (props) => {
  const [formObservableValues, setFormObservableValues] = useState({});
  const [checkboxConditionsKeys, setCheckboxConditionsKeys] = useState([]);
  const [consentConditionsKeys, setConsentConditionsKeys] = useState([]);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);

  const onHandleSetFormObservableValues = (field, value) => {
    setFormObservableValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <FormContext.Provider
      value={{
        formObservableValues,
        checkboxConditionsKeys,
        consentConditionsKeys,
        isConfirmButtonDisabled,
        onHandleSetFormObservableValues,
        setConsentConditionsKeys,
        setCheckboxConditionsKeys,
        setIsConfirmButtonDisabled,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

export default FormContext;

export { FormProvider };
