import { isWindow } from './isClient';

const SCRIPT_ID = 'breadcrumb-trail';

const createScriptPlaceholder = () => {
  const script = document.createElement('script');
  script.setAttribute('type', 'application/ld+json');
  script.id = SCRIPT_ID;
  document.head.appendChild(script);
};

const addBreadcrumbTrailToPageHeader = (data = {}) => {
  const script = document.getElementById(SCRIPT_ID);
  script.textContent = JSON.stringify(data);
};

const setBreadcrumbTrail = (data = []) => {
  if (!isWindow()) return;

  const obj = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: data.map((page, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: page.name,
      item: `${window.location.origin}${page.path}`,
    })),
  };

  addBreadcrumbTrailToPageHeader(obj);
};

export default { setBreadcrumbTrail, createScriptPlaceholder };
