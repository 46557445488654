import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import ButtonGTM from '../../components/form-components/Button/tracking/ButtonGTM';

export default class ButtonTracking {
  static listen() {
    EventBus.on('component/form/Confirm/click', (data) => {
      const buttonGTM = new ButtonGTM(data);
      buttonGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/form/Confirm/click', data);
      }
    });
  }
}
