import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { mediaApi } from '@sitecore-jss/sitecore-jss';

import { useTranslation } from 'react-i18next';
import VIEW_MODES from '../../../consts/viewModes';
import RoutableSitecoreLink from '../../../helpers/RoutableSitecoreLink';
import RouteLinkedRichText from '../../../helpers/RouteLinkedRichText';
import HtmlImage from '../../simple-components/HtmlImage';

export const getMobileOrDesktopField = (slide, mode, fieldDesktop, fieldMobile) => {
  const desktopField = slide?.fields[fieldDesktop] ?? {};
  const mobileField = slide?.fields[fieldMobile] ?? {};
  const mobileFieldValue = mobileField?.value ?? false;

  if (mode === VIEW_MODES.DESKTOP || (mode === VIEW_MODES.MOBILE && isEmpty(mobileFieldValue))) {
    return [desktopField, 'desktop'];
  }

  return [mobileField, 'mobile'];
};

const transformMediaSrc = (src) => {
  return mediaApi.updateImageUrl(src);
};

const SlideCtaLink = ({ data, mode }) => {
  const button = data?.fields?.ctaButton ?? {};
  const isMobileCta = data?.fields?.ctaButtonMobile?.value ?? false;
  const text = button?.value?.text ?? '';
  const shouldBeDisplayed =
    (mode === VIEW_MODES.MOBILE && isMobileCta) || mode === VIEW_MODES.DESKTOP;

  if (text && shouldBeDisplayed) {
    return (
      <RoutableSitecoreLink
        className="sg-btn btn-medium btn-transparent--grey-border hero-banner__cta-link"
        field={button}
        editable={false}
      >
        {text}
      </RoutableSitecoreLink>
    );
  }

  return null;
};

const SlideTextLink = ({ data, mode }) => {
  const button = data?.fields?.textLink ?? {};
  const isMobileLink = data?.fields?.textLinkMobile?.value ?? false;
  const text = button?.value?.text ?? '';

  if (text && ((mode === VIEW_MODES.MOBILE && isMobileLink) || mode === VIEW_MODES.DESKTOP)) {
    return <RoutableSitecoreLink className="hero-banner__text-link" field={button} />;
  }

  return null;
};

const VideoSlide = ({ slide, mode, isSliderLoaded, videoRef, getHeroInlineStyle }) => {
  const [backgroundVideo, vMode] = getMobileOrDesktopField(
    slide,
    mode,
    'desktopBackgroundVideo',
    'mobileBackgroundVideo',
  );
  const [backgroundImage] = getMobileOrDesktopField(
    slide,
    mode,
    'desktopBackground',
    'mobileBackground',
  );
  const backgroundVideoSrc = backgroundVideo?.value?.src ?? '';
  const backgroundImageSrc = backgroundImage?.value?.src ?? '';

  const videoClass = cx('video-container', {
    'video-container--v-dektop': vMode === 'desktop',
  });

  return (
    <video
      ref={videoRef}
      className={videoClass}
      poster={transformMediaSrc(isSliderLoaded ? backgroundImageSrc : '')}
      autoPlay
      loop
      muted
      playsInline
      key={backgroundVideoSrc}
      style={getHeroInlineStyle()}
    >
      <source src={transformMediaSrc(isSliderLoaded ? backgroundVideoSrc : '')} />
    </video>
  );
};

const ImageSlide = ({ slide, mode, isSliderLoaded, getHeroInlineStyle }) => {
  const inlineStyle = getHeroInlineStyle();

  const [backgroundImage] = getMobileOrDesktopField(
    slide,
    mode,
    'desktopBackground',
    'mobileBackground',
  );
  const backgroundImageSrc = backgroundImage?.value?.src ?? '';
  const imageWidth = backgroundImage?.value?.width ?? '';
  const imageHeight = backgroundImage?.value?.height ?? '';
  const isMobile = mode === VIEW_MODES.MOBILE;

  return (
    <HtmlImage
      src={isSliderLoaded ? backgroundImageSrc : ''}
      alt={slide.displayName ?? ''}
      width={imageWidth}
      height={imageHeight}
      className="hide-alternatives"
      style={inlineStyle}
      mw={!isMobile ? 1920 : 1199}
      mh={!isMobile ? null : 703}
    />
  );
};

const HeroSlide = ({ slide, mode, id, getHeroInlineStyle, showPlayPause }) => {
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);
  const [isVideoPause, setIsVideoPaused] = useState(false);

  const { t } = useTranslation();
  const videoRef = useRef();

  const isDesktop = mode === VIEW_MODES.DESKTOP;

  const [backgroundVideo] = getMobileOrDesktopField(
    slide,
    mode,
    'desktopBackgroundVideo',
    'mobileBackgroundVideo',
  );
  const isVideoSlide = backgroundVideo?.value?.src ?? '';
  const [title] = getMobileOrDesktopField(slide, mode, 'titleDesktop', 'titleMobile');
  const [subtitle] = getMobileOrDesktopField(slide, mode, 'subtitleDesktop', 'subtitleMobile');

  const heroBannerContentClass = () => {
    const blackTextMode = slide?.fields?.darkText?.value ?? false;

    const placementFieldName = isDesktop ? 'desktopPlacement' : 'mobilePlacement';
    const alignment = slide?.fields[placementFieldName]?.fields?.Value?.value ?? '';

    const verticalPlacementFieldName = isDesktop
      ? 'verticalDesktopPlacement'
      : 'verticalMobilePlacement';
    const verticalAlignment = slide?.fields[verticalPlacementFieldName]?.fields?.Value?.value ?? '';

    return cx('hero-banner__content', alignment, verticalAlignment, {
      'hero-banner__content--black': blackTextMode,
    });
  };

  const slideWrapper = (component) => {
    const mainLink = slide?.fields?.mainLink?.value?.href ?? '';

    if (mainLink) {
      return (
        <div className="hero-banner__slide-element" key={slide?.id}>
          <RoutableSitecoreLink field={slide?.fields?.mainLink}>{component}</RoutableSitecoreLink>
        </div>
      );
    }

    return (
      <div className="hero-banner__slide-element" key={slide?.id}>
        {component}
      </div>
    );
  };

  const playPauseButton = () => {
    if (isVideoSlide && showPlayPause && isDesktop) {
      const pauseButton = (
        <span className="hero-banner__play-pause" onClick={handlePlayPause}>
          <span className="icon-fn--pause" />
          {t('Pause')}
        </span>
      );

      const playButton = (
        <span className="hero-banner__play-pause" onClick={handlePlayPause}>
          <span className="icon-fn--play" />
          {t('Play')}
        </span>
      );

      return isVideoPause ? playButton : pauseButton;
    }

    return null;
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsVideoPaused(false);
    } else {
      videoRef.current.pause();
      setIsVideoPaused(true);
    }
  };

  useEffect(() => {
    setIsSliderLoaded(true);
  }, [id]);

  const slideContent = (
    <>
      <div className="hero-banner__info">
        <div className={heroBannerContentClass()}>
          <div className="container">
            <RouteLinkedRichText
              className="hero-banner__slide-title"
              field={title}
              editable={false}
            />
            <RouteLinkedRichText
              className="hero-banner__slide-subtitle"
              field={subtitle}
              editable={false}
            />

            <div className="hero-banner__slide-cta-wrapper">
              <SlideCtaLink data={slide} mode={mode} />
              <SlideTextLink data={slide} mode={mode} />
            </div>

            {playPauseButton()}
          </div>
        </div>
      </div>
      {isVideoSlide ? (
        <VideoSlide
          videoRef={videoRef}
          slide={slide}
          mode={mode}
          isSliderLoaded={isSliderLoaded}
          getHeroInlineStyle={getHeroInlineStyle}
        />
      ) : (
        <ImageSlide
          slide={slide}
          mode={mode}
          isSliderLoaded={isSliderLoaded}
          getHeroInlineStyle={getHeroInlineStyle}
        />
      )}
    </>
  );

  return slide && slideWrapper(slideContent);
};

export default HeroSlide;

HeroSlide.propTypes = {
  slide: PropTypes.object,
  mode: PropTypes.oneOf([VIEW_MODES.MOBILE, VIEW_MODES.DESKTOP]),
  showPlayPause: PropTypes.bool,
  getHeroInlineStyle: PropTypes.func,
};

HeroSlide.defaultProps = {
  slide: {},
  mode: VIEW_MODES.DESKTOP,
  id: null,
  showPlayPause: false,
  getHeroInlineStyle: () => {},
};
