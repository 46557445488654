import React, { useRef } from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
// note we're aliasing the router's link component name, since it conflicts with JSS' link component
import { Link as RouterLink } from 'react-router-dom';

/** React component that turns Sitecore link values that start with / into react-router route links which work without page refresh */
const RoutableSitecoreLink = (props) => {
  const hasValidHref = props?.field?.value?.href ?? false;
  const isEditing = props?.editable !== false && props?.field?.editable;
  const shouldApplyDerivedClassNames = props?.applyDerivedClassNames ?? true;
  const node = useRef();

  const getClassName = (value) => {
    if (!shouldApplyDerivedClassNames) return props.className;

    const className = value.class || props.className;
    return className;
  };

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if (hasValidHref && !isEditing) {
    const { value } = props.field;

    // determine if a link is a route or not. This logic may not be appropriate for all usages.
    // Since invites and booking portal are on old sitecore we can't use router for those links.
    // Change this when invites and booking will be on new sitecore.
    if (
      value.href.startsWith('/') &&
      !value.href.startsWith('/-/media/') &&
      !value.href.startsWith('/-/jssmedia/') &&
      !value.href.includes('/reservations') &&
      !value.href.includes('/rwsinvites')
    ) {
      const { href, querystring = '', anchor = '' } = { ...value };

      return (
        <RouterLink
          to={{
            pathname: href,
            search: querystring,
            hash: anchor,
            state: {
              withNoScroll: props.withNoScroll,
            },
          }}
          title={value.title}
          target={value.target}
          ref={node}
          className={getClassName(value)}
          {...(props.onClick && { onClick: props.onClick })}
        >
          {props.children || value.text || props.defaultText || value.href}
          {props.withArrow && <span className="link-icon icon-fn--right-open-big" />}
        </RouterLink>
      );
    }
  }

  const childProps = { ...props };
  delete childProps.defaultText;
  delete childProps.applyDerivedClassNames;
  delete childProps.withArrow;

  if (childProps.field?.value?.class) {
    childProps.className = getClassName(childProps.field?.value);
  }

  if (!isEditing && childProps.field?.value) {
    if (props.defaultText && !childProps.field.value.text) {
      childProps.field.value.text = props.defaultText;
    }
    if (childProps.field.value.querystring && !childProps.field.value.href.includes('?')) {
      childProps.field.value.href += childProps.field.value.querystring;
    }
  }
  return props.withArrow ? (
    <Link {...childProps}>
      {childProps.field.value.text}
      <span className="link-icon icon-fn--right-open-big" />
    </Link>
  ) : (
    <Link {...childProps} />
  );
};

export default RoutableSitecoreLink;

RoutableSitecoreLink.propTypes = {
  applyDerivedClassNames: PropTypes.bool,
  withArrow: PropTypes.bool,
};

RoutableSitecoreLink.defaultProps = {
  applyDerivedClassNames: true,
  withArrow: false,
};
