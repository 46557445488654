import React, { useState, useEffect, createContext } from 'react';

import { isWindow } from '../helpers/isClient';

const PopupContext = createContext();

const PopupProvider = (props) => {
  const [popupConfig, setPopupConfig] = useState({});
  const [popupContent, setPopupContent] = useState({});

  const openPopup = (id, content, shared = {}) => {
    setPopupConfig((prevConfig) => ({
      ...prevConfig,
      ...shared,
      [id]: true,
    }));

    if (!content) return;
    setPopupContent((prevContent) => ({
      ...prevContent,
      content,
    }));
  };

  const closePopup = (id, shared = {}) => {
    setPopupConfig((prevConfig) => ({
      ...prevConfig,
      ...shared,
      [id]: false,
    }));
  };

  useEffect(() => {
    if (isWindow()) {
      window.openPopup = openPopup;
    }
  }, []);

  return (
    <PopupContext.Provider
      value={{
        popupConfig,
        popupContent,
        closePopup,
      }}
    >
      {props.children}
    </PopupContext.Provider>
  );
};

export default PopupContext;

export { PopupProvider };
