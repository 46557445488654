import axios from 'axios';
import config from '../temp/config';

import { appendUrlQueryStringParameter } from '../helpers/urlHelper';

export class BaseAPI {
  constructor(root = config.sitecoreApiHost, axiosConfig) {
    this.cancelToken = null;
    this.root = root;

    const client = axios.create(axiosConfig);
    client.interceptors.response.use(this.handleSuccess, this.handleError);

    this.client = client;
  }

  handleSuccess = (response) => {
    return response;
  };

  handleError = (error) => {
    return Promise.reject(error);
  };

  get = ({ serverRelativePath, request, language, cancelToken }) => {
    if (cancelToken) {
      this.cancelToken = cancelToken;
    } else {
      this.clearToken();

      this.cancelToken = this.getCancelTokenSource();
    }

    const path = this.prepareRequiredQueryStringParameters(
      `${this.root}${serverRelativePath}`,
      language,
    );

    return this.client.get(path, { ...request, cancelToken: this.cancelToken.token });
  };

  post = ({ serverRelativePath, request, language }) => {
    this.clearToken();

    this.cancelToken = this.getCancelTokenSource();

    const path = this.prepareRequiredQueryStringParameters(
      `${this.root}${serverRelativePath}`,
      language,
    );

    return this.client.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: request?.data != null ? JSON.stringify(request.data) : null,
      cancelToken: this.cancelToken.token,
    });
  };

  put = ({ serverRelativePath, request, language }) => {
    this.clearToken();

    this.cancelToken = this.getCancelTokenSource();

    const path = this.prepareRequiredQueryStringParameters(
      `${this.root}${serverRelativePath}`,
      language,
    );

    return this.client.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: request?.data != null ? JSON.stringify(request.data) : null,
      cancelToken: this.cancelToken.token,
    });
  };

  delete = ({ serverRelativePath, request, language }) => {
    this.clearToken();

    this.cancelToken = this.getCancelTokenSource();

    const path = this.prepareRequiredQueryStringParameters(
      `${this.root}${serverRelativePath}`,
      language,
    );

    return this.client.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: request?.data != null ? JSON.stringify(request.data) : null,
      cancelToken: request?.cancelToken,
    });
  };

  prepareRequiredQueryStringParameters = (url, language) => {
    let result = appendUrlQueryStringParameter(url, 'sc_apikey', config.sitecoreApiKey);

    if (language) {
      result = appendUrlQueryStringParameter(result, 'sc_lang', language);
    }

    return result;
  };

  clearToken = () => {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }
  };

  getCancelTokenSource = () => {
    return axios.CancelToken.source();
  };
}
