import React from 'react';
import {
  withSitecoreContext,
  Placeholder,
  getChildPlaceholder,
} from '@sitecore-jss/sitecore-jss-react';

function NavigationStaticPlaceholder({ sitecoreContext, name, ...otherProps }) {
  if (!sitecoreContext.navigation){
    return null;
  }

  let placeholderData = sitecoreContext.navigation.sitecore.route;

  // if we have route data that places components in the target placeholder,
  // we're editing the definition item of the static item, so we should
  // render as a normal placeholder
  if (sitecoreContext.route) {
    const childPlaceholder = getChildPlaceholder(sitecoreContext.route, name);

    if (childPlaceholder && childPlaceholder.some((rendering) => rendering.componentName)) {
      placeholderData = sitecoreContext.route;
    }
  }

  return <Placeholder name={name} rendering={placeholderData} {...otherProps} />;
}

export default withSitecoreContext()(NavigationStaticPlaceholder);