export const productTypes = Object.freeze({
  ATTRACTION: 'Attraction',
  EVENT: 'Event',
  HOTEL: 'Hotel',
  RESTAURANT: 'Restaurant',
  VENUE: 'Venue',
  OFFER: 'Offer',
  GENERIC: 'Generic',
  PACKAGE: 'Package',
  RETAIL: 'Retail',
});
