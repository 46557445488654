import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';

import { isWindow } from '../helpers/isClient';

const ScrollContext = React.createContext();
const DEBOUNCE_TIME = 30;

const ScrollProvider = (props) => {
  const [scrollY, setScrollY] = useState(0);

  const onHandleScroll = debounce(() => {
    if (isWindow()) {
      setScrollY(window.scrollY);
    }
  }, DEBOUNCE_TIME);

  useEffect(() => {
    if (isWindow()) {
      window.addEventListener('scroll', onHandleScroll);
    }

    return () => {
      if (isWindow()) {
        window.removeEventListener('scroll', onHandleScroll);
      }
    };
  }, [onHandleScroll]);

  return (
    <ScrollContext.Provider
      value={{
        scrollY,
      }}
    >
      {props.children}
    </ScrollContext.Provider>
  );
};

export default ScrollContext;

export { ScrollProvider };
