import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import CartSubtotalGTM from '../../components/booking-components/CartSubtotal/tracking/CartSubtotalGTM';

export default class CartSubtotalTracking {
  static listen() {
    EventBus.on('component/CartSubtotal/Checkout/click', (data) => {
      const cartSubtotalGTM = new CartSubtotalGTM(data);
      cartSubtotalGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/CartSubtotal/Checkout/click', data);
      }
    });
  }
}
