import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import GridListingCardGTM from '../../components/listing-components/GridListing/tracking/GridListingCardGTM';

export default class GridListingCardTracking {
  static listen() {
    EventBus.on('component/GridListingCard/click', (data) => {
      const gridListingCardGTM = new GridListingCardGTM(data);
      gridListingCardGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/GridListingCard/click', data);
      }
    });

    EventBus.on('component/GridListingCard/productsLoaded', (data) => {
      const gridListingCardGTM = new GridListingCardGTM(data);
      gridListingCardGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/GridListingCard/productsLoaded', data);
      }
    });
  }
}
