import dayjs from 'dayjs';

import BookingLocalStorageHelper from 'helpers/booking/bookingLocalStorageHelper';

const bookingLocalStorageHelper = new BookingLocalStorageHelper();
const booking = bookingLocalStorageHelper.getBooking();

const rooms = booking[bookingLocalStorageHelper.bookingRoomsLocalStorageKey] ?? {};
const cart = booking[bookingLocalStorageHelper.bookingCartLocalStorageKey] ?? {};

const defaultCartExpiryDate = dayjs()
  .add(12, 'hours')
  .format();

const bookingDefaults = {
  rooms: {
    current: rooms.current ?? 1,
    total: rooms.total ?? 1,
    selected: rooms.selected ?? [],
    max: rooms.max ?? 1,
    roomOccupation: rooms.roomOccupation ?? [],
    roomsQuery: rooms.roomsQuery ?? [],
  },
  cart: {
    items: cart.items ?? [],
    expiryDate: cart.expiryDate ?? defaultCartExpiryDate,
    lastBaseListingPath: cart.lastBaseListingPath ?? '',
    cartQuery: cart.cartQuery ?? [],
    price: {
      total: {
        invitesPromo: cart.price?.total?.invitesPromo ?? 0,
        default: cart.price?.total?.default ?? 0,
      },
    },
  },
};

export default bookingDefaults;
