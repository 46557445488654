import { isWindow } from '../isClient';

export default class BookingLocalStorageHelper {
  bookingDataLocalStorageKey = 'bookingData';

  bookingRoomsLocalStorageKey = 'rooms';

  bookingCartLocalStorageKey = 'cart';

  getBooking() {
    if (!isWindow()) return {};

    const booking = localStorage.getItem(this.bookingDataLocalStorageKey);
    if (booking) {
      return JSON.parse(booking);
    }

    return {};
  }

  setBooking(booking) {
    if (!isWindow()) return {};
    return localStorage.setItem(this.bookingDataLocalStorageKey, JSON.stringify(booking));
  }
}
