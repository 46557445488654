import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import OffersGridListingGTM from '../../components/listing-components/OffersGridListing/tracking/OffersGridListingGTM';

export default class OffersGridListingTracking {
  static listen() {
    EventBus.on('component/OffersGridListing/onPageLoad', (data) => {
      const offersGridListingGTM = new OffersGridListingGTM(data);
      offersGridListingGTM.onPageLoad();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/OffersGridListing/onPageLoad', data);
      }
    });
  }
}
