import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import SelectionProgressGTM from '../../components/booking-components/SelectionProgress/tracking/SelectionProgressGTM';

export default class SelectionProgressTracking {
  static listen() {
    EventBus.on('component/SelectionProgress/AddToCart/click', (data) => {
      const selectionProgressGTM = new SelectionProgressGTM(data);
      selectionProgressGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/SelectionProgress/AddToCart/click', data);
      }
    });
  }
}
