import VIEW_MODES from '../consts/viewModes';
import { isWindow } from './isClient';

const checkViewMode = () => {
  if (isWindow() && window.innerWidth < 1200) {
    return VIEW_MODES.MOBILE;
  }

  return VIEW_MODES.DESKTOP;
};

export default checkViewMode;
