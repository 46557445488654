import EventBus from '../EventBus';
import GoogleTagManagerLogger from '../../helpers/tracking/GoogleTagManagerLogger';
import AddedToCartGTM from '../../components/booking-components/AddedToCart/tracking/AddedToCartGTM';

export default class AddedToCartTracking {
  static listen() {
    EventBus.on('component/AddedToCart/Select/click', (data) => {
      const addedToCartGTM = new AddedToCartGTM(data);
      addedToCartGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/AddedToCart/Select/click', data);
      }
    });

    EventBus.on('component/AddedToCart/addToCart/click', (data) => {
      const addedToCartGTM = new AddedToCartGTM(data);
      addedToCartGTM.click();

      if (GoogleTagManagerLogger.isEnabled()) {
        GoogleTagManagerLogger.log('component/AddedToCart/addToCart/click', data);
      }
    });
  }
}
